export const ShopIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <g>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M1 .5H.5V3h1V1.5h15V3h1V.5H1zm0 4H.5v13h17v-13H1zm.5 12v-11h15v11h-7v-6h-1v6h-7z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};
