import { FetcherKey, searchApiPath } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { querify, urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { PopularSearchKeywordsResponse } from '../response-models';
import { AutoCompleteSearchKeywordsResponse } from '../response-models/AutoCompleteSearchKeywordsResponse';

export const fetchPopularSearchKeywords = createFetcher<Record<string, never>, PopularSearchKeywordsResponse, string[]>(
  {
    key: FetcherKey.FetchPopularSearchKeywords,
    method: 'GET',
    url: urlJoin(searchApiPath, '/api/v4/keyword/popular'),
    validator: (data) => v.safeParse(PopularSearchKeywordsResponse, data).issues?.map((issue) => issue.message),
    transformer: (data) => data.data?.popularKeyword ?? [],
  },
);

export const fetchAutoCompleteSearchKeywords = createFetcher<
  { keyword: string },
  AutoCompleteSearchKeywordsResponse,
  string[]
>({
  key: FetcherKey.FetchAutoCompleteSearchKeywords,
  method: 'GET',
  url: (options) => urlJoin(searchApiPath, '/api/v4/auto-complete', querify(options)),
  validator: (data) => v.safeParse(AutoCompleteSearchKeywordsResponse, data).issues?.map((issue) => issue.message),
  transformer: (data) => data.data?.keywords ?? [],
});
