import { useEffect, useState } from 'react';

/**
 * 특정 값의 변화에 debounce를 적용하는 훅
 *
 * @param value debounce를 적용할 값
 * @param delay value가 변경된 뒤 얼마나 기다렸다 값을 갱신할지 (ms)
 * @returns debounce가 적용된 값
 *
 * @example
 * ```ts
 * const [searchKeyword, setSearchKeyword] = useState('');
 * const debouncedSearchKeyword = useDebouncedValue(searchKeyword, 300);
 * console.log(debouncedSearchKeyword) // debouncedSearchKeyword는 마지막 searchKeyword 변경이 일어나고 300ms 뒤에 값이 변경된다
 * ```
 */
export const useDebouncedValue = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (debouncedValue === value) {
      return;
    }

    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [debouncedValue, delay, value]);

  return debouncedValue;
};
