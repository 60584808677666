'use client';

import { Icon29, Light } from '@29cm/contexts-navigations-views';
import { useCloseAppInstallBottomSheetFor24HoursStore } from '@29cm/contexts-persistent-state/hooks';
import { generateAppInstallOneLink } from '@29cm/contexts-smart-link/services';
import { Button, Icon, TextButton } from '@29cm/ruler';
import { MouseEventHandler } from 'react';

export const AppInstallBottomSheet = () => {
  const { closeFor24Hours, setCloseFor24Hours } = useCloseAppInstallBottomSheetFor24HoursStore();

  if (closeFor24Hours) {
    return null;
  }

  const handleClick = () => {
    // TODO: 이벤트 수집
    setCloseFor24Hours();
    document.location.href = generateAppInstallOneLink('INSTALL_LAYER');
  };

  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setCloseFor24Hours();
  };

  return (
    <div
      className="pb-safe fixed inset-x-0 bottom-0 z-popup flex cursor-pointer flex-col gap-20 bg-[#000000cc] px-20 pt-18 backdrop-blur-[2px] md:hidden"
      onClick={handleClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <p className="text-l-bold text-on-color">
            29CM 앱 설치하고
            <br />더 많은 혜택을 받아보세요
          </p>
          <p className="text-m text-on-color opacity-80">최대 15% 할인 혜택</p>
        </div>

        <AppInstallIconAsset />
      </div>

      <div className="mb-20 flex flex-col gap-8">
        <Button priority="tertiary" size="small" stretch>
          앱으로 보기
        </Button>

        <TextButton priority="setWhite" size="small" className="[&>span]:text-s" bold={false} onClick={handleClose}>
          오늘 하루 보지 않기
        </TextButton>
      </div>
    </div>
  );
};

const AppInstallIconAsset = () => {
  return (
    <div className="relative h-[62px] w-[66px] pl-4 pt-[3px]">
      <div className="relative h-[51px] w-[51px]">
        <Light className="absolute left-1/2 top-1/2 z-content-1 -translate-x-1/2 -translate-y-1/2" />
        <div className="absolute left-0 top-0 z-content-2 flex h-full w-full items-center justify-center rounded-8 bg-primary">
          <Icon29 />
        </div>
      </div>
      <div className="min-h-20 min-w-20 absolute bottom-4 right-4 z-content-2 flex h-20 w-20 items-center justify-center rounded-full border border-solid border-line bg-on-white">
        <Icon type="download" size={12} />
      </div>
    </div>
  );
};
