type Callback<T = unknown> = (arg: T) => void;

/**
 * @description 특정 이벤트의 구독이 필요한 경우 사용할 수 있는 함수입니다.
 *
 * @property {Function} notify 이벤트를 발생시키는 함수입니다.
 * @property {Function} observe 이벤트를 구독하는 함수입니다.
 *
 * @example
 * ```tsx
 * const observer = createObserver();
 *
 * const setNumber = (value: number) => {
 *   ...
 *   observer.notify(value);
 * }
 *
 * observer.observe<number>((value) => console.log(`값이 ${value} 로 변경되었습니다.`))
 *
 * setNumber(1); // 값이 1 로 변경되었습니다.
 * setNumber(2); // 값이 2 로 변경되었습니다.
 * setNumber(3); // 값이 3 로 변경되었습니다.
 * ```
 */
export const createObserver = () => {
  const callbackMap = new Map<string, Set<Callback>>();

  const notify = (event: string, arg?: unknown) => {
    const callbacks = callbackMap.get(event);

    callbacks?.forEach((callback) => callback(arg));
  };

  const observe = <T>(event: string, callback: Callback<T>): (() => void) => {
    if (!callbackMap.has(event)) {
      callbackMap.set(event, new Set());
    }

    const callbacks = callbackMap.get(event);

    callbacks?.add(callback as Callback);

    return () => unobserve(event, callback as Callback);
  };

  const unobserve = (event: string, callback: Callback) => {
    const callbacks = callbackMap.get(event);

    callbacks?.delete(callback);

    if (callbacks?.size === 0) {
      callbackMap.delete(event);
    }
  };

  return {
    notify,
    observe,
  };
};
