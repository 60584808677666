import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';

export const CartCountResponse = wrapApiResponse(
  v.object({
    count: v.number(),
  }),
);

export type CartCountResponse = v.InferInput<typeof CartCountResponse>;
