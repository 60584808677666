import { Icon29 } from '@29cm/contexts-navigations-views';

export const AppInstallBannerContent = () => {
  return (
    <div className="flex h-[50px] max-h-[50px] min-h-[50px] flex-1 items-center justify-between bg-primary pl-16 pr-8 md:hidden">
      <div className="flex items-center">
        <div className="mr-8 flex h-28 w-28 items-center justify-center rounded-8 border border-solid border-[#303033]">
          <Icon29 width={14.5} height={9.5} />
        </div>
        <p className="text-s-bold text-on-color">
          29CM 앱 설치하고 <em className="flex-1 not-italic text-accent">15%</em> 할인 받기
        </p>
      </div>
      <button type="button" className="h-24 rounded-full !bg-accent px-12 text-xxs-medium text-on-color">
        앱으로 보기
      </button>
    </div>
  );
};
