import { loadScript } from '@29cm/utils-scripts';

type WindowWithDataLayer = Window & {
  dataLayer?: (Record<string, unknown> | unknown)[];
};

export const initGoogleTagManager = async (conversionId: string) => {
  await loadScript(`https://www.googletagmanager.com/gtag/js?id=${conversionId}`);

  const _window = window as WindowWithDataLayer;

  if (_window.dataLayer === undefined) {
    return;
  }

  return (name: string, properties: Record<PropertyKey, unknown> = {}) => {
    _window.dataLayer = _window.dataLayer || [];
    _window.dataLayer.push(['js', new Date()]);
    _window.dataLayer.push([
      'config',
      conversionId,
      {
        page_title: window.document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    ]);
    _window.dataLayer.push(['event', name, properties]);
  };
};
