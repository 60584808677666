import { GlobalNavigationCategoryGroupItem, GlobalNavigationCategoryItem } from '@29cm/contexts-navigations-constants';
import { tv } from 'tailwind-variants';
import { CategoryGroupItemList } from './CategoryGroupItemList';
import { CategoryItemList } from './CategoryItemList';

const name = tv({
  base: 'relative cursor-pointer text-[12px] font-semibold text-primary min-[720px]:text-[16px]',
  variants: {
    isFocused: {
      true: "before:absolute before:-bottom-4 before:left-0 before:h-4 before:w-full before:bg-primary before:content-['']",
    },
  },
});

interface SecondaryMenuItemProps {
  category: GlobalNavigationCategoryItem | GlobalNavigationCategoryGroupItem;
  isFocused: boolean;
}

export const SecondaryMenuItem: React.FC<SecondaryMenuItemProps> = ({ category, isFocused }) => {
  return (
    <div>
      <div className={name({ isFocused })}>{category.name}</div>
      {isFocused && !isFakeCategory(category) && (
        <div className="z-30 absolute left-0 top-[calc(100%-20px)] flex max-h-[calc(100vh-90%)] min-h-[400px] w-full flex-col border-t border-line bg-default px-20 pb-40 pt-20 min-[720px]:px-48">
          {isCategoryGroupItem(category) ? (
            <CategoryGroupItemList categoryGroupItemCode={category.code} />
          ) : (
            <CategoryItemList categoryItemCode={category.code} />
          )}
        </div>
      )}
    </div>
  );
};

const isCategoryGroupItem = (category: GlobalNavigationCategoryItem): category is GlobalNavigationCategoryGroupItem =>
  (category as GlobalNavigationCategoryGroupItem).subCategoryCodes !== undefined;

/**
 * 실제 존재하는 카테고리가 아닌, UI용으로 하드코딩된 카테고리를 선별합니다
 * 이 카테고리들은 code 값이 존재하지 않기 때문에 -1으로 하드코딩 되어있습니다
 */
const isFakeCategory = (category: GlobalNavigationCategoryItem) => category.code < 0;
