import { loadScript } from '@29cm/utils-scripts';
import { createAnalytics } from './createAnalytics';

type MobonInstance = {
  setSSL: (ssl: boolean) => void;
  setData: (key: string, data: unknown) => void;
  sendCart: () => void;
  sendRf: () => void;
  sendRfShop: () => void;
  sendConv: () => void;
};

type WindowWithMobon = Window & {
  EN?: new () => MobonInstance;
};

const MOBON_SCRIPT_URL = 'https://cdn.megadata.co.kr/js/en_script/3.6/enliple_min3.6.js';

export const mobon = createAnalytics({
  platform: 'mobon',
  initializer: async () => {
    await loadScript(MOBON_SCRIPT_URL);

    const ENClass = (window as WindowWithMobon).EN;

    if (ENClass === undefined) {
      return;
    }

    const instance = new ENClass();

    instance.setSSL(true);
    instance.sendRf();

    return instance;
  },
  tracker: () => {
    // TODO: 주문 관련 이벤트 수집에 필요한 기능 구현 (home 도메인에서는 initialize 만 해주고 있어 미구현)
  },
});
