import { fetchCategoryById } from '@29cm/contexts-categories-services';
import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { CategoryList } from './CategoryList';

interface CategoryItemListProps {
  categoryItemCode: number;
}

export const CategoryItemList: React.FC<CategoryItemListProps> = ({ categoryItemCode }) => {
  const { result } = useCachedFetch(fetchCategoryById, { id: categoryItemCode });

  // TODO: success 체크하는 result 유틸 만들기
  if (result?.success !== true) {
    return null;
  }

  return <CategoryList className="max-h-[300px]" categoryItem={result.data} />;
};
