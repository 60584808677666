export const Light = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="83"
      height="83"
      viewBox="0 0 83 83"
      fill="none"
      className={className}
    >
      <g filter="url(#filter0_f_408_13169)">
        <circle cx="41.5" cy="41.5" r="25.5" fill="#9B9B9B" />
      </g>
      <defs>
        <filter
          id="filter0_f_408_13169"
          x="0"
          y="0"
          width="83"
          height="83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_408_13169" />
        </filter>
      </defs>
    </svg>
  );
};
