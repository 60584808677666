'use client';

import { useCartCount } from '@29cm/contexts-carts/hooks';
import { orderAppPath } from '@29cm/contexts-common-constants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { urlJoin } from '@29cm/utils-url';
import { NavItem } from './NavItem';

export const CartNavItem: React.FC = () => {
  const cartCount = useCartCount();

  return (
    <NavItem
      href={urlJoin(orderAppPath, `/cart`)}
      label="SHOPPING BAG"
      icon={<EmoIcon type="bag" size={18} fill />}
      badgeCount={cartCount}
    />
  );
};
