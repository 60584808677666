import { commerceApiPath, FetcherKey } from '@29cm/contexts-common-constants';
import { createFetcher, withCredentials } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { CartCountResponse } from '../response-schemas';

export const fetchCartCount = createFetcher<Record<string, never>, CartCountResponse, number>({
  key: FetcherKey.FetchGroupCategoryById,
  method: 'GET',
  url: () => urlJoin(commerceApiPath, '/api/v4/cart-item/item-count'),
  validator: (data) => v.safeParse(CartCountResponse, data).issues?.map((i) => i.message),
  transformer: (data) => data.data?.count ?? 0,
  middlewares: [withCredentials],
});
