import {
  customerAppPath,
  customerLegacyAppPath,
  homeLegacyAppPath,
  mypageLegacyAppPath,
  orderAppPath,
} from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

export interface FooterSiteMapSection {
  name: string;
  menus: { name: string; href: string }[];
}

export interface FooterCompanyInfoItem {
  name: string;
  value: string;
  href?: string;
  button?: { label: string; href: string };
}

export const footerSiteMapSections: FooterSiteMapSection[] = [
  {
    name: 'ABOUT US',
    menus: [
      { name: '29CM 소개', href: urlJoin(homeLegacyAppPath, '/home/about') },
      { name: '인재채용', href: 'https://www.29cmcareers.co.kr' },
      { name: '상시 할인 혜택', href: urlJoin(homeLegacyAppPath, '/event/benefit-guide/guide') },
    ],
  },
  {
    name: 'MY ORDER',
    menus: [
      { name: '주문배송', href: urlJoin(orderAppPath, '/my-order/list') },
      { name: '취소/교환/반품 내역', href: urlJoin(mypageLegacyAppPath, '/my-order/cancel-list') },
      { name: '상품리뷰 내역', href: urlJoin(orderAppPath, '/my-order/review') },
      { name: '증빙서류발급', href: urlJoin(orderAppPath, '/my-order/receipt') },
    ],
  },
  {
    name: 'MY ACCOUNT',
    menus: [
      { name: '회원정보수정', href: urlJoin(mypageLegacyAppPath, '/edit/reconfirm') },
      { name: '회원등급', href: urlJoin(orderAppPath, '/my-order/grade') },
      { name: '마일리지현황', href: urlJoin(orderAppPath, '/my-order/mileage') },
      { name: '쿠폰', href: urlJoin(orderAppPath, '/my-order/coupon') },
    ],
  },
  {
    name: 'HELP',
    menus: [
      { name: '1:1 상담내역', href: urlJoin(orderAppPath, '/my-order/cscenter/qna/qna-mantoman-list') },
      { name: '마케팅 제휴, 입점, 대량 주문 문의', href: urlJoin(customerAppPath, '/contact-us') },
      { name: '상품 Q&A내역', href: urlJoin(orderAppPath, '/my-order/cscenter/qna/qna-product-list') },
      { name: '공지사항', href: urlJoin(customerLegacyAppPath, '/notice-cs/notice-list') },
      { name: 'FAQ', href: urlJoin(customerLegacyAppPath, '/faq-cs/faq-list') },
      { name: '고객의 소리', href: urlJoin(customerLegacyAppPath, '/voc') },
    ],
  },
];

export const companyInfoItems: FooterCompanyInfoItem[] = [
  { name: '상호명', value: '(주)무신사' },
  { name: '사업장소재지', value: '서울특별시 성동구 아차산로 13길 11, 1층 (성수동2가, 무신사캠퍼스 엔1)' },
  { name: '팩스', value: '070-8622-7737' },
  { name: '사업자등록번호', value: '211-88-79575' },
  {
    name: '통신판매업신고',
    value: '2022-서울성동-01952',
    button: { label: '사업자정보확인', href: 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2118879575&apv_perm_no=' },
  },
  { name: '전화번호', value: '1644-0560', href: 'tel:1644-0560' },
  { name: '이메일', value: 'customer@29cm.co.kr', href: 'mailto:customer@29cm.co.kr' },
  { name: '대표', value: '조만호, 박준모' },
  { name: '개인정보 보호책임자', value: '정광은' },
  { name: '호스팅서비스', value: '(주)무신사' },
];
