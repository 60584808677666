import { fetchGroupCategoryById } from '@29cm/contexts-categories-services';
import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { CategoryList } from './CategoryList';

interface CategoryGroupItemListProps {
  categoryGroupItemCode: number;
}

export const CategoryGroupItemList: React.FC<CategoryGroupItemListProps> = ({ categoryGroupItemCode }) => {
  const { result } = useCachedFetch(fetchGroupCategoryById, { id: categoryGroupItemCode });

  // TODO: success 체크하는 result 유틸 만들기
  if (result?.success !== true) {
    return null;
  }

  return (
    <ul className="flex flex-wrap gap-y-32">
      {result.data.subCategories?.map((subCategoryItem) => (
        <li key={subCategoryItem.code} className="flex max-w-[218px] flex-1 flex-col gap-6 text-xs">
          <CategoryList categoryItem={subCategoryItem} />
        </li>
      ))}
    </ul>
  );
};
