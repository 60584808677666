'use client';

import { shopAppPath } from '@29cm/contexts-common-constants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { isMdScreen } from '@29cm/contexts-common-utils';
import { urlJoin } from '@29cm/utils-url';
import { useCallback } from 'react';
import { useSearchDialogContext } from '../SearchDialogContext';
import { NavItem } from './NavItem';

interface SearchNavItemProps {
  className?: string;
}

export const SearchNavItem: React.FC<SearchNavItemProps> = ({ className }) => {
  const { setIsOpen } = useSearchDialogContext();

  const handleSearch = useCallback(() => {
    if (isMdScreen()) {
      setIsOpen(true);
      return;
    }

    document.location.href = urlJoin(shopAppPath, `/search/start`);
  }, [setIsOpen]);

  return (
    <NavItem
      className={className}
      href="/"
      label=""
      icon={<EmoIcon type="search" size={18} fill />}
      onClick={handleSearch}
    />
  );
};
