'use client';

import { useFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { fetchAutoCompleteSearchKeywords } from '@29cm/contexts-search-services';
import { useDebouncedValue } from '@29cm/hooks-timings';
import { SearchFormAutoCompleteListItem } from './SearchFormAutoCompleteListItem';

interface SearchFormAutoCompleteListProps {
  keyword: string;
}

export const SearchFormAutoCompleteList: React.FC<SearchFormAutoCompleteListProps> = ({ keyword }) => {
  const debouncedKeyword = useDebouncedValue(keyword, 200);
  // NOTE: useCachedFetch 사용 시 refetch 사이에 result data가 초기화되는 현상이 있어 useFetch를 사용함
  const { result } = useFetch(fetchAutoCompleteSearchKeywords, { keyword: debouncedKeyword });
  const autoCompleteKeywords = getResultData(result, []);

  if (autoCompleteKeywords.length === 0) {
    return null;
  }

  return (
    <ul className="absolute left-0 top-[calc(100%+4px)] flex w-full flex-col border border-line bg-default py-10">
      {autoCompleteKeywords.map((autoCompleteKeyword) => (
        <SearchFormAutoCompleteListItem
          key={autoCompleteKeyword}
          searchKeyword={debouncedKeyword}
          autoCompleteKeyword={autoCompleteKeyword}
        />
      ))}
    </ul>
  );
};
