'use client';

import { EmoIcon } from '@29cm/contexts-common-ruler';
import { useSearchDialogContext } from '../SearchDialogContext';

export const SearchButton: React.FC = () => {
  const { setIsOpen } = useSearchDialogContext();
  return (
    <button className="hidden cursor-pointer min-[1200px]:block" onClick={() => setIsOpen(true)}>
      <EmoIcon type="search" size={44} fill />
    </button>
  );
};
