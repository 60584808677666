import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app';
import { createAnalytics } from './createAnalytics';

const FIREBASE_APP_NAME = '29CM-WEB';

// FIXME: 환경변수로 관리
const FIREBASE_OPTIONS: Readonly<FirebaseOptions> = {
  apiKey: 'AIzaSyCs_FWgyaFRpMQQSGbFEBUbSLYdZEGWAMs',
  authDomain: 'cm-web-db26a.firebaseapp.com',
  databaseURL: 'https://cm-web-db26a.firebaseio.com',
  projectId: 'cm-web-db26a',
  storageBucket: 'cm-web-db26a.appspot.com',
  messagingSenderId: '558061215795',
  appId: '1:558061215795:web:570550384924ae9ad0c346',
  measurementId: 'G-TZEE8GDKJ6',
};

export const firebase = createAnalytics({
  platform: 'firebase',
  initializer: () => {
    initializeApp(FIREBASE_OPTIONS, FIREBASE_APP_NAME);

    const hasApp = getApps().findIndex((app) => app.name === FIREBASE_APP_NAME) > -1;

    if (!hasApp) {
      return;
    }

    return getAnalytics(getApp(FIREBASE_APP_NAME));
  },
  tracker: (instance, name, properties) => {
    if (instance === undefined) {
      return;
    }

    logEvent(instance, name, properties);
  },
  userSetter: (instance, userId) => {
    if (instance === undefined) {
      return;
    }

    setUserId(instance, userId);
    setUserProperties(instance, { user_id: userId });
  },
});
