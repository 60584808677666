'use client';

import { AnalyticsProvider } from '@29cm/contexts-analytics/features';
import {
  cts,
  facebookPixel,
  firebase,
  googleAdWords,
  googleAnalytics,
  googleRemarketing,
  kakaoPixel,
  mobon,
  naverAd,
  wptgTagScript,
} from '@29cm/contexts-analytics/services';
import { AuthenticationProvider } from '@29cm/contexts-auth/features';
import { GlobalCacheProvider } from '@29cm/contexts-common-contexts';
import { CookieProvider } from '@29cm/contexts-cookie/features';
import { UserProvider } from '@29cm/contexts-users/features';
import { PropsWithChildren } from 'react';

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <CookieProvider>
      <UserProvider>
        <AuthenticationProvider>
          <AnalyticsProvider
            // TODO: 미사용 마케팅 스크립트 확인 및 제거
            services={[
              firebase,
              googleAnalytics,
              cts,
              mobon,
              kakaoPixel,
              facebookPixel,
              naverAd,
              googleAdWords,
              googleRemarketing,
              wptgTagScript,
            ]}
          >
            <GlobalCacheProvider>{children}</GlobalCacheProvider>
          </AnalyticsProvider>
        </AuthenticationProvider>
      </UserProvider>
    </CookieProvider>
  );
};
