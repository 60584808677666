import { Link } from '@29cm/contexts-common-views';
import { MouseEventHandler, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface NavItemProps {
  className?: string;
  href?: string;
  label: string;
  icon: ReactNode;
  badgeCount?: number;
  onClick?: MouseEventHandler;
}

export const NavItem: React.FC<NavItemProps> = ({ className, href, label, icon, badgeCount = 0, onClick }) => {
  if (!href) {
    return (
      <li className={twMerge('cursor-pointer', className)}>
        <div className="flex items-center gap-4" onClick={onClick}>
          <NavItemContent icon={icon} badgeCount={badgeCount} label={label} />
        </div>
      </li>
    );
  }

  return (
    <li className={className}>
      <Link className="flex items-center gap-4" href={href} onClick={onClick}>
        <NavItemContent icon={icon} badgeCount={badgeCount} label={label} />
      </Link>
    </li>
  );
};

const NavItemContent = ({ label, icon, badgeCount = 0 }: Pick<NavItemProps, 'icon' | 'badgeCount' | 'label'>) => {
  return (
    <>
      <div className="relative">
        {icon}
        {badgeCount > 0 && (
          <div className="absolute -right-10 -top-10 flex h-18 w-18 items-center justify-center rounded-full bg-red-500 text-[9px] text-on-color">
            {badgeCount}
          </div>
        )}
      </div>
      <span className="hidden text-[10px] leading-3 min-[1200px]:inline-block">{label}</span>
    </>
  );
};
