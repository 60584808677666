'use client';

import { searchAppPath } from '@29cm/contexts-common-constants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { useEffect, useState } from 'react';
import { useSearchDialogContext } from '../SearchDialogContext';
import { SearchFormAutoCompleteList } from './SearchFormAutoCompleteList';

export const SearchForm: React.FC = () => {
  const { isOpen } = useSearchDialogContext();
  const [keyword, setKeyword] = useState<string>('');
  const trimmedKeyword = keyword.trim();

  useEffect(() => {
    // 검색 다이얼로그가 열리고 닫힐 때 검색 키워드를 초기화한다
    setKeyword('');
  }, [isOpen]);

  return (
    <form className="relative flex gap-16 border-b-4 border-on-black" action={searchAppPath} method="get">
      <input
        className="flex-1 text-[36px] font-semibold outline-none placeholder:text-title-l-bold placeholder:text-[#d4d4d4]"
        name="keyword"
        placeholder="Search"
        value={keyword}
        onChange={(event) => setKeyword(event.currentTarget.value)}
      />
      <button type="submit">
        <EmoIcon type="search" size={48} fill />
      </button>
      {trimmedKeyword.length > 0 && <SearchFormAutoCompleteList keyword={trimmedKeyword} />}
    </form>
  );
};
