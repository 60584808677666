'use client';

import { shopAppPath } from '@29cm/contexts-common-constants';
import { Link } from '@29cm/contexts-common-views';
import {
  GlobalNavigationCategoryGroupItem,
  GlobalNavigationCategoryItem,
  bestCategory,
  groupCategories,
  sideCategories,
  singleCategories,
} from '@29cm/contexts-navigations-constants';
import { urlJoin } from '@29cm/utils-url';
import { useState } from 'react';
import { SecondaryMenuItem } from './SecondaryMenuItem';

export const SecondaryMenuList: React.FC = () => {
  const categories: (GlobalNavigationCategoryItem | GlobalNavigationCategoryGroupItem)[] = [
    ...groupCategories,
    ...singleCategories,
  ];
  const [focusedCategory, setFocusedCategory] = useState<
    GlobalNavigationCategoryItem | GlobalNavigationCategoryGroupItem
  >();

  return (
    <div
      className="flex gap-20"
      onPointerLeave={() => {
        setFocusedCategory(undefined);
      }}
    >
      <ul className="flex flex-wrap items-center gap-x-16 gap-y-10 pb-4 min-[720px]:gap-x-20">
        <li onPointerEnter={() => setFocusedCategory(bestCategory)}>
          <Link href={urlJoin(shopAppPath, '/best-items')}>
            <SecondaryMenuItem category={bestCategory} isFocused={focusedCategory === bestCategory} />
          </Link>
        </li>
        {categories.map((category) => (
          <li key={category.code} onPointerEnter={() => setFocusedCategory(category)}>
            <SecondaryMenuItem category={category} isFocused={focusedCategory === category} />
          </li>
        ))}
        {/* TODO: bg 컬러를 border 컬러로 사용하기 */}
        <div className="h-16 w-2 bg-[#e4e4e4] min-[720px]:h-20" />
        {sideCategories.map((category) => (
          <li key={category.name}>
            <Link
              href={category.url}
              className="text-[12px] font-extralight italic text-primary min-[720px]:text-[16px]"
            >
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
