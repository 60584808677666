import { createAnalytics } from './createAnalytics';
import { initGoogleTagManager } from './google-tag-manager';

// FIXME: 환경변수로 관리
const GOOGLE_AD_WORDS_CONVERSION_ID = 'AW-781236085';

export const googleAdWords = createAnalytics({
  platform: 'googleAdWords',
  initializer: async () => {
    return initGoogleTagManager(GOOGLE_AD_WORDS_CONVERSION_ID);
  },
  tracker: (instance, name, properties) => {
    instance?.(name, properties);
  },
});
