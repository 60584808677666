import {
  apihubApiPath,
  apihubCacheApiPath,
  appEnv,
  customerLegacyAppPath,
  FetcherKey,
  mypageLegacyAppPath,
} from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import { parse } from 'date-fns';
import * as v from 'valibot';
import { Notice } from '../interfaces';
import { NoticeListResponse } from '../response-schemas';

export const fetchNoticeList = createFetcher<{ limit: number; noticeType: 'official' }, NoticeListResponse, Notice[]>({
  key: FetcherKey.FetchNoticeList,
  method: 'GET',
  url: ({ limit, noticeType }) =>
    `${urlJoin(
      appEnv === 'development' ? apihubApiPath : apihubCacheApiPath,
      '/notice/',
    )}?limit=${limit}&notice_type=${noticeType}`,
  validator: (data) => v.safeParse(NoticeListResponse, data).issues?.map((i) => i.message),
  transformer: (data) =>
    data.results.map((d) => ({
      id: d.notice_no,
      type: d.notice_type,
      title: d.title,
      contents: d.contents,
      url:
        d.notice_type === 'recruit'
          ? urlJoin(mypageLegacyAppPath, `/recruit?notice_no=${d.notice_no}&notice_type=${d.notice_type}`)
          : urlJoin(
              customerLegacyAppPath,
              `/notice-cs/notice-list?notice_no=${d.notice_no}&notice_type=${d.notice_type}`,
            ),
      startDate: d.start_date !== null ? parse(d.start_date, 'yyyy-MM-dd', new Date()) : undefined,
      endDate: d.end_date !== null ? parse(d.end_date, 'yyyy-MM-dd', new Date()) : undefined,
    })),
});
