import { createAnalytics } from './createAnalytics';
import { initGoogleTagManager } from './google-tag-manager';

// FIXME: 환경변수로 관리
const GOOGLE_REMARKETING_CONVERSION_ID = 'AW-860724285';

export const googleRemarketing = createAnalytics({
  platform: 'googleRemarketing',
  initializer: async () => {
    return initGoogleTagManager(GOOGLE_REMARKETING_CONVERSION_ID);
  },
  tracker: (instance, name, properties) => {
    instance?.(name, properties);
  },
});
