import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { fetchCartCount } from '../services';

export const useCartCount = (): number => {
  // TODO: cookie와 연동하기
  const { result } = useCachedFetch(fetchCartCount, {});
  const cartCount = getResultData(result, 0);
  return cartCount;
};
