import * as v from 'valibot';

export const GroupCategoryByIdResponse = v.object({
  category_group_no: v.number(),
  category_list: v.array(
    v.object({
      category_code: v.number(),
      category_name: v.string(),
      raw_category_name: v.string(),
      medium_category_list: v.array(
        v.object({
          category_code: v.number(),
          category_name: v.string(),
        }),
      ),
    }),
  ),
  is_deleted: v.string(),
  insert_timestamp: v.string(),
  updated_timestamp: v.string(),
  name: v.string(),
  sequence: v.number(),
  is_display: v.string(),
  admin_no: v.number(),
});

export type GroupCategoryByIdResponse = v.InferInput<typeof GroupCategoryByIdResponse>;
