import * as v from 'valibot';

export const SingleCategoryByIdResponse = v.object({
  count: v.number(),
  next: v.nullable(v.string()),
  previous: v.nullable(v.string()),
  results: v.pipe(
    v.array(
      v.object({
        category: v.object({
          category_code: v.number(),
          category_name: v.string(),
          count: v.number(),
          category2: v.array(
            v.object({
              category_code: v.number(),
              category_name: v.string(),
              count: v.number(),
              category3: v.array(
                v.object({
                  category_code: v.number(),
                  category_name: v.string(),
                  count: v.number(),
                }),
              ),
            }),
          ),
        }),
      }),
    ),
    v.minLength(1),
  ),
});

export type SingleCategoryByIdResponse = v.InferInput<typeof SingleCategoryByIdResponse>;
