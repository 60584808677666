'use client';

import { useAuthenticationContext } from '@29cm/contexts-auth/features';
import { getRedirectUriParam } from '@29cm/contexts-auth/services';
import { authAppPath, workspaceEnv } from '@29cm/contexts-common-constants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { urlJoin } from '@29cm/utils-url';
import { MouseEventHandler, useCallback } from 'react';
import { NavItem } from './NavItem';

interface LoginNavItemProps {
  className?: string;
}

export const LoginNavItem: React.FC<LoginNavItemProps> = ({ className }) => {
  const { isLogged, logout } = useAuthenticationContext();

  const handleLogin = useCallback<MouseEventHandler>(() => {
    const redirectUriParam = getRedirectUriParam();
    document.location.href =
      workspaceEnv === 'auth' ? '/login' : urlJoin(authAppPath, `/login`, redirectUriParam ?? '');
  }, []);

  const handleLogout = useCallback<MouseEventHandler>(async (event) => {
    event.preventDefault();
    logout();
  }, []);

  if (isLogged) {
    return (
      <NavItem
        className={className}
        href="/"
        label="LOGOUT"
        icon={<EmoIcon type="lockoff" size={18} fill />}
        onClick={handleLogout}
      />
    );
  }

  return (
    <NavItem className={className} label="LOGIN" icon={<EmoIcon type="lock" size={18} fill />} onClick={handleLogin} />
  );
};
