import { contentAppPath, homeLegacyAppPath, mypageLegacyAppPath, searchAppPath } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

export interface GlobalTabBarItem {
  name: 'HOME' | 'SHOP' | 'SEARCH' | '29MAGAZINE' | 'MY';
  url: string;
  /**
   * RegExp.prototype.source를 이용해 만들어진 문자열
   * 서버 컴포넌트 <-> 클라이언트 컴포넌트 간 통신을 위해 RegExP 대신 문자열을 사용함
   */
  urlRegex: string;
}

export const tabBarItems: GlobalTabBarItem[] = [
  {
    name: 'HOME',
    url: homeLegacyAppPath,
    urlRegex: /(\/|\/home)/i.source,
  },
  {
    name: 'SHOP',
    url: urlJoin(homeLegacyAppPath, '/list/shop'),
    urlRegex: /\/(shop|best|event|style|lookbook)/i.source,
  },
  {
    name: 'SEARCH',
    url: urlJoin(searchAppPath, '/start'),
    urlRegex: /\/n?(search)(-.+)?/i.source,
  },
  {
    name: '29MAGAZINE',
    url: urlJoin(contentAppPath, '/29magazine'),
    urlRegex: /\/29magazine/i.source,
  },
  {
    name: 'MY',
    url: mypageLegacyAppPath,
    urlRegex: /\/mypage\//i.source,
  },
];
