import { loadScript } from '@29cm/utils-scripts';
import { createAnalytics } from './createAnalytics';

type WindowWithKakaoPixel = Window & {
  kakaoPixel?: (trackId: string) => KakaoPixel;
};

type KakaoPixelEventCode = 'pageView' | 'completeRegistration' | 'search' | 'viewContent' | 'viewCart' | 'purchase';

type KakaoPixel = Record<KakaoPixelEventCode, (tag?: unknown) => void>;

// FIXME: 환경변수로 관리
const KAKAO_PIXEL_TRACK_ID = '3654417029993772491';
const KAKAO_PIXEL_SCRIPT_URL = '//t1.daumcdn.net/adfit/static/kp.js';

export const kakaoPixel = createAnalytics({
  platform: 'kakaoPixel',
  initializer: async () => {
    await loadScript(KAKAO_PIXEL_SCRIPT_URL);

    return (window as WindowWithKakaoPixel).kakaoPixel?.(KAKAO_PIXEL_TRACK_ID);
  },
  tracker: (instance, name, properties) => {
    instance?.[name as KakaoPixelEventCode]?.(properties);
  },
});
