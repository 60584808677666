/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMdScreen } from '@29cm/contexts-common-utils';
import { createAnalytics } from './createAnalytics';

type WindowWithCTS = Window & {
  /**
   * @description 간접 전환 일수
   */
  _L_LALT?: string;

  /**
   * @description 전환 페이지 값 (ex. 구매완료: 'ODR', 회원가입: 'RGR')
   */
  _TRK_PI?: string;

  /**
   * @description 주문 상품코드
   */
  _TRK_OP?: string;

  /**
   * @description 주문 수량
   */
  _TRK_OE?: string;

  /**
   * @description 주문 금액
   */
  _TRK_OA?: string;

  /**
   * @description 주문 번호
   */
  _TRK_ODN?: string;

  BSAnalyticsObj?: string;
  _tcts_m?: CTSFunction;
};

type CTSFunction = (...args: string[]) => void;

export const cts = createAnalytics({
  platform: 'cts',
  initializer: async () => {
    (window as WindowWithCTS)._L_LALT = '30';

    const instance = await loadCTSScript();

    if (isMdScreen()) {
      instance?.('13925', 'CMCOM');
    } else {
      instance?.('13926', 'CMCOM');
    }

    return instance;
  },
  tracker: () => {
    // TODO: 주문 관련 이벤트 수집에 필요한 기능 구현 (home 도메인에서는 initialize 만 해주고 있어 미구현)
  },
});

const loadCTSScript = (): Promise<CTSFunction | undefined> => {
  // eslint-disable-next-line compat/compat
  return new Promise<CTSFunction | undefined>((resolve) => {
    (function (b: any, s, t, c, k) {
      b[k] = s;
      b[s] =
        b[s] ||
        function () {
          (b[s].q = b[s].q || []).push(arguments);
        };
      const f = t.getElementsByTagName(c)[0];
      const j = <HTMLScriptElement>t.createElement(c);
      j.async = true;
      j.src = '//fs.bizspring.net/fs4/l4cts.v4.2.min.js';
      j.onload = function () {
        return resolve((window as WindowWithCTS)._tcts_m);
      };
      f?.parentNode?.insertBefore(j, f);
    })(window, '_tcts_m', document, 'script', 'BSAnalyticsObj');
  });
};
