'use client';

import { searchAppPath } from '@29cm/contexts-common-constants';
import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { Link } from '@29cm/contexts-common-views';
import { fetchPopularSearchKeywords } from '@29cm/contexts-search-services';
import { urlJoin } from '@29cm/utils-url';
import { useSearchDialogContext } from '../SearchDialogContext';

export const PopularSearchKeywordList: React.FC = () => {
  const { setIsOpen } = useSearchDialogContext();
  const { result } = useCachedFetch(fetchPopularSearchKeywords, {});

  const shouldRenderTitle = result?.success === true && result.data.length !== 0;

  return (
    <section className="flex flex-col gap-12">
      {shouldRenderTitle && <h2 className="text-title-xl-bold">인기 검색어</h2>}
      <ul className="flex flex-col gap-[9px]">
        {result?.success === true &&
          result.data.map((popularKeyword) => (
            <li key={popularKeyword} className="text-xxl-medium">
              <Link
                href={urlJoin(searchAppPath, `?keyword=${popularKeyword}`)}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {popularKeyword}
              </Link>
            </li>
          ))}
      </ul>
    </section>
  );
};
