'use client';

import { searchAppPath } from '@29cm/contexts-common-constants';
import { Link } from '@29cm/contexts-common-views';
import { urlJoin } from '@29cm/utils-url';

interface SearchFormAutoCompleteListItemProps {
  searchKeyword: string;
  autoCompleteKeyword: string;
}

export const SearchFormAutoCompleteListItem: React.FC<SearchFormAutoCompleteListItemProps> = ({
  searchKeyword,
  autoCompleteKeyword,
}) => {
  const parts = autoCompleteKeyword.split(new RegExp(`(${searchKeyword})`, 'i'));

  return (
    <li>
      <Link
        href={urlJoin(searchAppPath, `?keyword=${autoCompleteKeyword}`)}
        className="flex h-40 items-center px-20 hover:bg-silent-hover"
      >
        {parts.map((part, index) =>
          // NOTE: 첫 번째로 일치하는 키워드만 하이라이팅 하기 위해 index 조건을 추가합니다
          part === searchKeyword && index <= 1 ? (
            <span key="highlight" className="text-accent">
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </Link>
    </li>
  );
};
