'use client';

import { useSearchDialogContext } from '../SearchDialogContext';
import { PopularSearchKeywordList } from './PopularSearchKeywordList';

export const SearchKeywordList: React.FC = () => {
  const { isOpen } = useSearchDialogContext();

  if (!isOpen) {
    return null;
  }

  return <PopularSearchKeywordList />;
};
