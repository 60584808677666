export const Icon29 = ({
  className,
  width = 29,
  height = 19,
}: {
  className?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 29 19"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_424_8359)">
        <path
          d="M22.2837 0.173828C18.8242 0.173828 16.0195 2.89759 16.0195 6.25777C16.0195 9.61796 18.754 12.1889 22.127 12.1889C22.3417 12.1889 22.5438 12.1486 22.7524 12.128L18.0566 18.7734H21.133L27.5432 9.48627C28.1985 8.53726 28.5489 7.41111 28.5489 6.25777C28.5489 2.89759 25.7442 0.173828 22.2837 0.173828ZM22.2837 10.0144C20.213 10.0144 18.5342 8.33246 18.5342 6.2573C18.5342 4.18215 20.213 2.50018 22.2837 2.50018C24.3545 2.50018 26.0333 4.18215 26.0333 6.2573C26.0333 8.33246 24.3545 10.0144 22.2837 10.0144Z"
          fill="white"
        />
        <path
          d="M11.9655 5.72625C11.9655 2.64584 9.38813 0.148438 6.20832 0.148438C3.02851 0.148438 0.451172 2.64584 0.451172 5.72625H2.96583C2.96583 3.9304 4.41775 2.47479 6.20832 2.47479C7.9989 2.47479 9.45081 3.9304 9.45081 5.72625C9.45081 6.50842 9.15098 7.26012 8.61306 7.82671L0.451639 16.4212V18.7475H11.9655V16.4212H3.80405L10.5201 9.3484C11.4477 8.37128 11.9655 7.07454 11.9655 5.72578V5.72625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_424_8359">
          <rect width="28.0972" height="18.5996" fill="white" transform="translate(0.451172 0.173828)" />
        </clipPath>
      </defs>
    </svg>
  );
};
