import { appEnv, contentAppPath, shopAppPath, workspaceEnv } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

export interface GlobalNavigationPrimaryMenuItem {
  name: string;
  url: string;
  urlRegex: RegExp;
}

export interface GlobalNavigationCategoryItem {
  code: number;
  name: string;
  label: string;
}

export interface GlobalNavigationCategoryGroupItem extends GlobalNavigationCategoryItem {
  subCategoryCodes: number[];
}

export interface GlobalNavigationSideItem {
  name: string;
  url: string;
  urlRegex: RegExp;
}

export const primaryMenuItems: GlobalNavigationPrimaryMenuItem[] = [
  {
    name: 'Special-Order',
    url: workspaceEnv === 'shop' ? '/special-order' : urlJoin(shopAppPath, '/special-order'),
    urlRegex: /\/special-order/i,
  },
  {
    name: 'Showcase',
    url: workspaceEnv === 'shop' ? '/showcase' : urlJoin(shopAppPath, '/showcase'),
    urlRegex: /\/showcase|issue/i,
  },
  {
    name: 'PT',
    // 임의로 파라미터(`?Z25i`)를 붙여서 GNB에서 PT 링크를 클릭하여 진입한 것으로 분기함 (#FE-119)
    url: urlJoin(shopAppPath, '/pt?Z25i'),
    urlRegex: /\/pt/i,
  },
  {
    name: '29Magazine',
    url: urlJoin(contentAppPath, '/29magazine'),
    urlRegex: /\/29magazine/i,
  },
];

export const bestCategory: GlobalNavigationCategoryItem = {
  // BEST는 카테고리가 아니며 메뉴로만 존재한다
  // 가짜 카테고리는 code 값을 0보다 낮게 설정하여 구분한다
  code: -1,
  name: 'BEST',
  label: 'BEST',
};

export const groupCategories: GlobalNavigationCategoryGroupItem[] = [
  {
    /**
     *  그룹카테고리의 경우 어드민에서 그룹카테고리를 생성하기에
     *  해당 그룹카테고리의 대카테고리가 index순으로 생성됨
     *  실제 카테고리 분류 번호와는 상이함
     * @author naoull@29cm.co.kr
     * @since 20.11.18 - [FE] GNB 그룹 카테고리 관련 수정 건 FE-1721)
     */
    code: appEnv === 'production' ? 1 : appEnv === 'qa' ? 11 : 1,
    /**
     * 그룹 카테고리 선택자 밑줄 활성화를 위한 데이터 추가
     * : 그룹카테고리 하위의 대카테고리 4개로 구성됨. (여성의류, 악세사리, 여성가방, 여성신발)
     * : 어드민에서 대카테고리 수정 및 변경 시 해당 카테고리 맵핑 수정 필요.
     * @author naoull@29cm.co.kr
     * @since 20.11.17 - [FE] GNB 그룹 카테고리 선택자 활성화 (#FE-1721)
     */
    subCategoryCodes:
      appEnv === 'production'
        ? [268100100, 269100100, 270100100, 271100100]
        : appEnv === 'qa'
          ? [253100100, 254100100, 277100100, 330100100]
          : [268100100, 254100100, 269100100, 270100100],
    name: 'WOMEN',
    label: '우먼',
  },
  {
    code: appEnv === 'production' ? 3 : appEnv === 'qa' ? 10 : 20,
    subCategoryCodes:
      appEnv === 'production'
        ? [272100100, 273100100, 274100100, 275100100]
        : appEnv === 'qa'
          ? [255100100, 331100100, 329100100, 328100100]
          : [272100100, 273100100, 275100100, 274100100],
    name: 'MEN',
    label: '맨',
  },
];

export const singleCategories: GlobalNavigationCategoryItem[] = [
  {
    code: appEnv === 'production' ? 291100100 : appEnv === 'qa' ? 291100100 : 291100100,
    name: 'INTERIOR',
    label: '가구,인테리어',
  },
  {
    code: appEnv === 'production' ? 292100100 : appEnv === 'qa' ? 292100100 : 292100100,
    name: 'KITCHEN',
    label: '주방,생활',
  },
  {
    code: appEnv === 'production' ? 293100100 : appEnv === 'qa' ? 293100100 : 293100100,
    name: 'ELECTRONICS',
    label: '가전',
  },
  {
    code: appEnv === 'production' ? 294100100 : appEnv === 'qa' ? 294100100 : 294100100,
    name: 'DIGITAL',
    label: '컴퓨터,디지털',
  },
  {
    code: appEnv === 'production' ? 266100100 : appEnv === 'qa' ? 264100100 : 264100100,
    name: 'BEAUTY',
    label: '뷰티',
  },
  {
    code: appEnv === 'production' ? 289100100 : appEnv === 'qa' ? 289100100 : 289100100,
    name: 'FOOD',
    label: '푸드',
  },
  {
    code: appEnv === 'production' ? 286100100 : appEnv === 'qa' ? 286100100 : 286100100,
    name: 'LEISURE',
    label: '레저',
  },
  {
    code: appEnv === 'production' ? 290100100 : appEnv === 'qa' ? 290100100 : 290100100,
    name: 'KIDS',
    label: '유아,아동',
  },
  {
    code: appEnv === 'production' ? 265100100 : appEnv === 'qa' ? 265100100 : 265100100,
    name: 'CULTURE',
    label: '컬처',
  },
];

export const sideCategories: GlobalNavigationSideItem[] = [
  {
    name: 'Event',
    url: workspaceEnv === 'shop' ? '/event' : urlJoin(shopAppPath, '/event'),
    urlRegex: /\/event/i,
  },
  {
    name: 'Lookbook',
    url: urlJoin(shopAppPath, '/lookbook'),
    urlRegex: /\/style|lookbook/i,
  },
];
