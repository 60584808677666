import { loadScript } from '@29cm/utils-scripts';
import { createAnalytics } from './createAnalytics';

type WindowWithNaverAd = Window & {
  wcs_add?: {
    wa: string;
  };
  wcs?: {
    inflow?: (arg: string) => void;
    cnv?: (type: string, value: string) => unknown;
  };
  _nasa?: {
    cnv?: unknown;
  };
  wcs_do?: (arg: WindowWithNaverAd['_nasa']) => void;
};

// FIXME: 환경변수로 관리
const NAVER_AD_ACCOUNT_ID = 's_9388a9367e8';
const NAVER_AD_SCRIPT_URL = '//wcs.naver.net/wcslog.js';

export const naverAd = createAnalytics({
  platform: 'naverAd',
  initializer: async () => {
    await loadScript(NAVER_AD_SCRIPT_URL);

    const _window = window as WindowWithNaverAd;

    if (_window.wcs_add === undefined) {
      _window.wcs_add = {
        wa: NAVER_AD_ACCOUNT_ID,
      };
    }

    if (_window._nasa === undefined) {
      _window._nasa = {};
    }

    if (_window.wcs !== undefined) {
      _window.wcs.inflow?.('29cm.co.kr');
      _window.wcs_do?.(_window._nasa);
    }
  },
  tracker: () => {
    // TODO: 주문 관련 이벤트 수집에 필요한 기능 구현 (home 도메인에서는 initialize 만 해주고 있어 미구현)
  },
});
