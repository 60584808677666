import { HttpMiddleware } from '../../interfaces';

export const withCredentials: HttpMiddleware = ({ requestOptions, ...rest }) => {
  // FIXME: http 관련 서비스를 common에서 분리하고, cookie-service 사용하여 accessToken 구현하기
  const accessToken = '';

  const requestOptionsWithCredentials: RequestInit = {
    ...requestOptions,
    headers: {
      ...requestOptions?.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return {
    ...rest,
    requestOptions: requestOptionsWithCredentials,
  };
};
