import { apihubApiPath, apihubCacheApiPath, appEnv, FetcherKey } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { CategoryItem } from '../models';
import { GroupCategoryByIdResponse, SingleCategoryByIdResponse } from '../response-models';

export const fetchGroupCategoryById = createFetcher<{ id: number }, GroupCategoryByIdResponse, CategoryItem>({
  key: FetcherKey.FetchGroupCategoryById,
  method: 'GET',
  url: ({ id }) =>
    urlJoin(appEnv === 'development' ? apihubApiPath : apihubCacheApiPath, `/item/category/group/${id}/`),
  validator: (data) => v.safeParse(GroupCategoryByIdResponse, data).issues?.map((i) => i.message),
  transformer: (data) => ({
    code: data.category_group_no,
    name: data.name,
    subCategories: data.category_list.map((c1) => ({
      code: c1.category_code,
      name: c1.category_name,
      subCategories: c1.medium_category_list.map((c2) => ({
        code: c2.category_code,
        name: c2.category_name,
      })),
    })),
  }),
});

export const fetchCategoryById = createFetcher<{ id: number }, SingleCategoryByIdResponse, CategoryItem>({
  key: FetcherKey.FetchSingleCategoryById,
  method: 'GET',
  url: ({ id }) =>
    `${urlJoin(appEnv === 'development' ? apihubApiPath : apihubCacheApiPath, '/item/category')}/?category1_code=${id}`,
  validator: (data) => v.safeParse(SingleCategoryByIdResponse, data).issues?.map((i) => i.message),
  transformer: (data) => ({
    code: data.results[0]!.category.category_code,
    name: data.results[0]!.category.category_name,
    subCategories: data.results[0]!.category.category2.map((c1) => ({
      code: c1.category_code,
      name: c1.category_name,
      subCategories: c1.category3.map((c2) => ({
        code: c2.category_code,
        name: c2.category_name,
      })),
    })),
  }),
});
