import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';

export const PopularSearchKeywordsResponse = wrapApiResponse(
  v.object({
    popularKeyword: v.array(v.string()),
  }),
);

export type PopularSearchKeywordsResponse = v.InferInput<typeof PopularSearchKeywordsResponse>;
