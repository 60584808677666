'use client';

import { EmoIcon } from '@29cm/contexts-common-ruler';
import { useSearchDialogContext } from '../SearchDialogContext';

export const CloseButton: React.FC = () => {
  const { setIsOpen } = useSearchDialogContext();
  return (
    <button className="self-end" onClick={() => setIsOpen(false)}>
      <EmoIcon type="close" size={48} />
    </button>
  );
};
