import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';

export const AutoCompleteSearchKeywordsResponse = wrapApiResponse(
  v.object({
    keywords: v.array(v.string()),
  }),
);

export type AutoCompleteSearchKeywordsResponse = v.InferInput<typeof AutoCompleteSearchKeywordsResponse>;
