import { isMdScreen } from '@29cm/contexts-common-utils';
import { loadScript } from '@29cm/utils-scripts';
import { createAnalytics } from './createAnalytics';

type WptgTagScriptFunction = () => WptgTagScriptFunctionReturns;

type WptgTagScriptFunctionReturns = {
  /**
   * @description 고객넘버 등 Unique ID (ex. 로그인ID, 고객넘버등)를 암호화하여 대입.
   *
   * 로그인하지 않은 사용자는 어떠한 값 도 대입하지 않습니다.
   */
  wp_hcuid: string;

  /**
   * @description 광고주 코드
   */
  ti: string;

  /**
   * @description 트래킹 태그 타입 ('Item', 'Cart', 'PurchaseComplete', 'Home')
   * @default 'Home'
   */
  ty: string;

  /**
   * @description 디바이스 종류 (web 또는 mobile)
   */
  device: 'web' | 'mobile';

  items: unknown[];
};

type WindowWithWptgTagScript = Window & {
  wptg_tagscript_vars?: WptgTagScriptFunction[];
};

export const WPTG_TAG_SCRIPT_TI = '38400';

const WPTG_TAG_SCRIPT_URL = 'https://cdn-aitg.widerplanet.com/js/wp_astg_4.0.js';

export const wptgTagScript = createAnalytics({
  platform: 'wptgTagScript',
  initializer: async () => {
    // FIXME: Webview 에서 실행되지 않도록 처리
    await loadScript(WPTG_TAG_SCRIPT_URL);

    const instance = (window as WindowWithWptgTagScript).wptg_tagscript_vars ?? [];

    return instance;
  },
  tracker: () => {
    // TODO: 주문 관련 이벤트 수집에 필요한 기능 구현 (home 도메인에서는 initialize 만 해주고 있어 미구현)
  },
  userSetter: (instance, userId) => {
    instance.push(() => ({
      wp_hcuid: userId,
      ti: WPTG_TAG_SCRIPT_TI,
      ty: 'Home',
      device: isMdScreen() ? 'web' : 'mobile',
      items: [],
    }));
  },
});
