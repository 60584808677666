'use client';

import { useFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { fetchNoticeList } from '@29cm/contexts-notices/services';
import { isNewNotice } from '@29cm/contexts-notices/utils';
import { SiteMapList } from './SiteMapList';

export const NoticeSection = () => {
  // TODO: server component로 구현하기
  const { result } = useFetch(fetchNoticeList, { limit: 5, noticeType: 'official' });
  const notices = getResultData(result, []);

  return (
    <SiteMapList
      title="NOTICE"
      items={notices.map((notice) => ({
        name: notice.title,
        href: notice.url,
        postfix: isNewNotice(notice) ? (
          <div className="flex h-[17px] w-[17px] items-center justify-center bg-accent p-2 font-campton text-[8px] text-on-color">
            N
          </div>
        ) : null,
      }))}
    />
  );
};
