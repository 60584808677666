import * as v from 'valibot';

export const NoticeListResponse = v.object({
  count: v.number(),
  next: v.nullable(v.string()),
  previous: v.nullable(v.string()),
  results: v.array(
    v.object({
      notice_no: v.number(),
      // TODO: type enum 정의하기
      notice_type: v.string(),
      title: v.string(),
      contents: v.string(),
      start_date: v.nullable(v.string()),
      end_date: v.nullable(v.string()),
      upload_files: v.array(v.string()),
      is_deleted: v.string(),
      insert_timestamp: v.string(),
      updated_timestamp: v.string(),
      is_display: v.string(),
      is_popup: v.string(),
    }),
  ),
});

export type NoticeListResponse = v.InferInput<typeof NoticeListResponse>;
