import { Link } from '@29cm/contexts-common-views';
import { ReactNode } from 'react';

interface SiteMapListProps {
  title: string;
  items: { name: string; href: string; postfix?: ReactNode }[];
}

export const SiteMapList: React.FC<SiteMapListProps> = ({ title, items }) => {
  return (
    <section className="flex flex-col gap-12">
      <h2 className="font-campton text-m-bold">{title}</h2>
      <ul className="flex flex-col gap-10">
        {items.map((item) => (
          <li key={item.name}>
            <Link className="flex items-center gap-8 text-s" href={item.href}>
              <span className="line-clamp-1">{item.name}</span>
              {item.postfix}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
