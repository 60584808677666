'use client';

import { useAnalytics } from '@29cm/contexts-analytics/hooks';
import { generateAppInstallOneLink } from '@29cm/contexts-smart-link/services';
import { AppInstallBannerContent } from './AppInstallBannerContent';

export const AppInstallBanner = () => {
  const track = useAnalytics();

  // TODO: 이벤트명 한 파일에서 관리 (SSOT 준수)
  const trackClick = () => track('click_top_app_install_banner', undefined, { platforms: { firebase: true } });

  const handleClick = () => {
    trackClick();
    document.location.href = generateAppInstallOneLink('TOP_BANNER');
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <AppInstallBannerContent />
    </div>
  );
};
