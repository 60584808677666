import { createPromiseStore } from '@29cm/contexts-common-stores';
import { createObserver } from '@29cm/utils-functions';
import { Analytics, AnalyticsPlatform } from '../interfaces';

type AnalyticsOptions<T> = {
  /**
   * @description 애널리틱스 플랫폼명
   * @example 'firebase' | 'amplitude' | 'braze'
   */
  platform: AnalyticsPlatform;

  /**
   * @description 애널리틱스 SDK 의 초기화 함수
   * @returns {(Instance | Promise<Instance>)} SDK 자체의 인스턴스를 반환해야 합니다.
   */
  initializer: () => T | Promise<T>;

  /**
   * @description 애널리틱스 SDK 의 커스텀 이벤트 수집 함수
   * @param {Instance} instance initializer 에서 반환된 인스턴스를 전달합니다.
   * @param {string} name 커스텀 이벤트명
   * @param {object} properties 커스텀 이벤트 프로퍼티
   */
  tracker: (instance: T, name: string, properties?: Record<PropertyKey, unknown>) => void;

  /**
   * @description 애널리틱스 SDK 의 user property 설정 함수 (선택)
   * @param {Instance} instance initializer 에서 반환된 인스턴스를 전달합니다.
   * @param {string} userId 유저 아이디
   */
  userSetter?: (instance: T, userId: string) => void;
};

/**
 * @description 각각 사용 방법이 다른 애널리틱스 SDK 들의 동작과 사용법의 일관성을 맞추기 위한 함수입니다.
 * 
 * 애널리틱스 SDK 가 초기화 되기 전에 tracker, userSetter 가 호출될 경우 초기화가 완료된 후에 일괄 실행됩니다.
 * 
 * @example
 * ```ts
 *export const firebase = createAnalytics({
    platform: 'firebase',
    initializer: () => {
      ...
      return getAnalytics(getApp(FIREBASE_APP_NAME));
    },
    tracker: (instance, name, properties) => {
      logEvent(instance, name, properties);
    },
    userSetter: (instance, userId) => {
      setUserId(instance, userId);
      setUserProperties(instance, { user_id: userId });
    },
  });
 * ```
 */
export const createAnalytics = <T>({ platform, initializer, tracker, userSetter }: AnalyticsOptions<T>): Analytics => {
  const observer = createObserver();
  const { batch } = createPromiseStore(platform);

  let instance: T | undefined;

  return {
    platform,
    init: batch(async () => {
      try {
        if (instance !== undefined) {
          return;
        }

        instance = await initializer();

        // instance 가 생성되면 구독 함수들을 일괄 실행합니다.
        observer.notify(platform, instance);
      } catch (error) {
        console.error(`Error occurred while initializing ${platform}: `, error);
        return;
      }
    }),
    track: (name, properties) => {
      if (instance !== undefined) {
        tracker(instance, name, properties);
        return;
      }

      // instance 가 생성되지 않았을 때 실행된 경우, 초기화된 후에 실행되도록 합니다.
      observer.observe<T>(platform, (instance) => {
        tracker(instance, name, properties);
      });
    },
    setUser: (userId) => {
      if (userSetter === undefined) {
        return;
      }

      if (instance !== undefined) {
        userSetter?.(instance, userId);
        return;
      }

      // instance 가 생성되지 않았을 때 실행된 경우, 초기화된 후에 실행되도록 합니다.
      observer.observe<T>(platform, (instance) => {
        userSetter?.(instance, userId);
      });
    },
  };
};
